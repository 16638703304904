[data-video-modal-trigger] {
    cursor: pointer;
}

.images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .image {
        color: $primary-inverse;
        text-decoration: none;
        margin: 0 30px 100px 0;
        width: 360px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        .image-img {
            position: relative;
            width: 100%;
            height: 203px;
            background-position: center !important;
            background-size: cover !important;
            border-radius: 3px;
            margin: 0 0 20px;

            i {
                bottom: 25px;
                right: 25px;
                font-size: 3.5em;
                position: absolute;
            }
        }

        .image-desc {
            padding: 0 10px;

            .image-title {
                padding: 0;
                margin: 0 0 10px 0;
            }

            .image-txt {
                padding: 0;
                margin: 0;
                color: $primary-lightplus;
                font-size: 0.9em;
            }
        }
    }
}

.modal {
    &.modal-video {
        .modal-content {
            background: none;
            border: none;

            .modal-body {
                padding: 0;
                display: flex;
                justify-content: center;
            }
        }
    }

    .modal-loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-player {
        display: none;
    }
}
