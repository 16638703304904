.btn-secondary {
    display: inline-block;
    border-radius: 23px;
    border: 1px solid $secondary;
    color: $secondary;
    text-decoration: none;
    padding: 10px 24px;
    transition: all 0.5s ease;

    &:hover {
        transition: all 0.5s ease;
        background: $secondary;
        color: $primary-inverse;
    }
}

.btn-dark {
    display: inline-block;
    border-radius: 23px;
    color: $primary-inverse;
    background: $primary;
    text-decoration: none;
    padding: 15px 24px;
    transition: all 0.5s ease;
}
