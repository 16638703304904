.artists {
    padding: 0 100px;
}

.artist {
    position: relative;
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 150px 0;
    background-position: center right;
    background-repeat: no-repeat;

    &:nth-child(2n) {
        background-position: center left;
    }

    .artist-content {
        z-index: 50;
        width: 33%;
    }

    .artist-firstname,
    .artist-lastname {
        font-size: 3.5em;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
    }

    .artist-txt {
        margin: 30px 0 20px;
    }

    .artist-social {
        margin: 0 0 0 30px;

        a {
            display: block;
            color: $primary-lightplus;
            text-decoration: none;
            font-size: 0.9em;

            i {
                font-size: 1em;
                margin: 0 8px 0 0;
            }
        }
    }
}
