.cat-titles {
    position: relative;
    display: block;
    text-align: center;
    margin: 105px auto -275px;
    z-index: 50;

    .cat-title {
        font-size: 4.5em;
        text-transform: uppercase;
        margin: 0 0 5px;
        padding: 0;
        line-height: 0.9em;
        letter-spacing: 0.15em;
    }

    .cat-subtitle {
        margin: 0;
        padding: 0;
        font-size: 2em;
        color: $secondary;
        font-weight: 600;
    }
}

.instruments {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .instrument {
        text-align: center;
        align-items: stretch;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        position: relative;
        width: 25%;

        &:hover {
            img {
                top: -20px;
                transition: all 0.3s ease;
            }
        }

        img {
            position: relative;
            top: 0;
            transition: all 0.3s ease;
            width: 185.25px;
            height: 492.48px;
        }

        .title {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1.5em;
            margin: 35px 0 0;
        }

        .desc {
            margin: 5px auto 20px;
            width: 70%;
        }
    }
}

.instru-titles {
    .cat-title {
        color: $secondary;
        text-align: center;
        font-size: 1.5em;
        font-weight: 600;
        margin: 0;
    }

    .instru-title {
        text-align: center;
        font-size: 4em;
        text-transform: uppercase;
        margin: 15px auto 0;
        line-height: 0.9em;
        letter-spacing: 0.15em;
        font-weight: 700;
        width: 80%;
    }

    .instru-desc {
        text-align: center;
        font-size: 0.9em;
        width: 50%;
        margin: 15px auto 60px;
        line-height: 1.3em;
    }
}

.instru-img {
    text-align: center;

    img {
        width: 81%;
    }

    .reflection {
        transform: rotatex(180deg) translatey(15px);
        mask-image: linear-gradient(transparent 82%, white 100%);
        -webkit-mask-image: linear-gradient(transparent 82%, white 100%);
        filter: blur(15px);
        opacity: 0.6;
        margin: 25px 0 -235px;
        padding: 10px 0;
    }
}

.instru-tech-title {
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-size: 1.7em;
    margin: 110px 0 40px;
}

.instru-sep {
    width: 55px;
    border: 1px $secondary solid;
    margin: 0 auto 80px;
}

.instru-tech-items {
    .instru-tech-item {
        width: 50%;
        float: left;

        .instru-tech-items-name {
            text-transform: uppercase;
            margin: 0 0 8px 0;
            font-size: 1.2em;
            font-weight: 700;
        }

        .instru-tech-items-value {
            margin: 0 0 55px 0;
            font-size: 0.9em;
        }
    }
}

.instru-price-block {
    text-align: center;
    color: $secondary;
    font-weight: 600;
    margin: 100px 0 0;

    .instru-price-txt {
        font-size: 1.6em;
    }

    .instru-price-price {
        font-size: 2.1em;
    }

    .instru-price-btn {
        color: #fff;
        background: $secondary;
        font-weight: 400;
        font-size: 1.1em;
        border-radius: 30px;
        margin: 35px 0 0;
        padding: 15px 30px;
    }
}

.instru-grid-1 {
    font-size: 0px;

    div.instru-grid-small,
    div.instru-grid-hori,
    div.instru-grid-vert {
        margin: 0 25px 0 0;
        background-position: center center;
        display: inline-block;
        position: relative;

        &:last-child {
            margin: 0;
        }

        i {
            bottom: 25px;
            right: 25px;
            font-size: 50px !important;
            position: absolute;
        }
    }

    .instru-grid-1-top {
        margin: 0 0 25px 0;
    }

    .instru-grid-1-bot {
        margin: 35px 0 0 0;
    }

    .instru-grid-1-big {
        float: left;
    }
}

.instru-grid-small {
    height: 265px;
    width: 360px;
    background-size: auto 100%;
}

.instru-grid-vert {
    height: 565px;
    width: 360px;
    background-size: auto 100%;
}

.instru-grid-hori {
    height: 265px;
    width: 750px;
    background-size: 100% auto;
}
