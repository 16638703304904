.shopping {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;

    .products {
    }

    .products-txt {
        width: 360px;
        padding: 55px 40px;
        background: $secondary;
        box-sizing: border-box;
        flex-shrink: 0;
        flex-grow: 0;

        .products-txt-title {
            text-transform: uppercase;
            margin: 0;
            font-size: 1.8em;
        }

        .products-txt-sep {
            border: 2px solid $primary-inverse;
            margin: 30px 0;
            width: 60px;
        }

        .products-txt-txt {
            color: $primary;
            font-size: 0.85em;
            line-height: 1.5em;
            font-weight: 500;
            margin: 0 0 17px;
        }
    }
}

.products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;

    .product {
        margin: 0 30px 100px 0;
        width: 360px;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }

        .product-img {
            width: 360px;
            height: 270px;
            background-size: auto 100%;
            background-position: center center;
            margin: 0 0 25px;
        }

        .product-desc {
            padding: 0 13px;

            .product-title {
                color: $primary-inverse;
                text-transform: uppercase;
                font-size: 1.1em;
                margin: 0 0 5px;
            }

            .product-price {
                color: $secondary;
                font-size: 1.2em;
                font-weight: 500;
                margin: 0 0 10px;
            }

            .product-cat {
                font-size: .9em;
                color: $primary-lightplus;
                margin: 0;
            }
        }
    }
}
