.wip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "montserrat", sans-serif;
    text-align: center;
    padding: 100px 0 0;

    .wip-logo {
        height: 100px;
        margin: 0 0 20px 0;
    }

    .wip-title {
        color: #fff;
        font-weight: 200;
        margin: 0 0 20px 0;
    }

    .wip-sub {
        color: #ad9370;
        font-family: 'Playfair Display', serif;
        font-style: italic;
        font-weight: 200;
        font-size: 1.3em;
        margin: 0 0 25px 0;
    }

    .wip-line {
        height: 40px;
        width: 1px;
        border: none;
        border-right: 1px solid #ad9370;
        margin: 0 0 25px 0;
    }

    .wip-txt {
        color: rgba(255,255,255,0.2);
        text-align: center;
        font-size: 0.8em;

        a {
            display: block;
            color: rgba(255,255,255,0.2);
            font-size: 1.8em;
            margin: 3px 0;

            &:hover {
                color: #ad9370;
            }
        }
    }
}
