@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/modal";

@import "wip";

$primary: #000;
$primary-inverse: #fff;
$primary-light: #191414;
$primary-lightplus: #747171;

$secondary: #ad9370;

$background: #000;
$background-line: #1A1611;

.text-secondary {
    color: $secondary;
}

.align-self-end {
   align-self: flex-end;
}

.flash-success {
    text-align: center;
    font-weight: 700;
    font-size: 1.5em;
}

.hidden {
    display: none;
}

.clear {
    clear: both;
}

@import "layout";
@import "buttons";
@import "pages/home";
@import "pages/instrument";
@import "pages/image";
@import "pages/artist";
@import "pages/office";
@import "pages/info";
@import "pages/contact";
@import "pages/shopping";
