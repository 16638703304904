.contact-form {
    background: $secondary;
    width: 50%;
    margin: 100px auto;
    padding: 45px;
    box-sizing: border-box;

    label {
        display: block;
        margin: 0 0 5px;
        color: $primary;
        font-weight: 500;
    }

    input, textarea {
        border: 0;
        border-radius: 3px;
        padding: 20px 20px;
        margin: 0 0 35px;
        width: 100%;
        box-sizing: border-box;
    }

    textarea {
        height: 230px;
        margin: 0 0 10px;
    }

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;
            color: #913308;
            margin: 0 0 5px;
            font-weight: 500;
        }
    }

    em {
        color: $primary;
        font-size: .8em;
    }

    .btns {
        text-align: right;
        margin: 35px 0 0;

        button {
            background: $primary;
            color: $primary-inverse;
            cursor: pointer;
            font-size: 1.2em;
            margin: 0 auto;
        }
    }
}
