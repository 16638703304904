$page-width: 1180px;

body {
    width: 100%;
    min-width: $page-width;
    padding: 0;
    margin: 0;
    font-family: "montserrat", sans-serif;
    color: $primary-inverse;
    overflow-x: hidden;

    background-image: repeating-linear-gradient(
        90deg,
        $background-line,
        $background-line 1px,
        $background 1px,
        $background 200px
    );

    h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
        font-weight: 700;
    }

    .body {
        align-items: center;
    }

    .wrapper {
        width: $page-width;
        margin: 0 auto;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        margin: 30px 0 0;
        padding: 0 30px;

        .menu {
            padding: 0;
            margin: 0;
            font-size: 0.9em;
            z-index: 99;

            .menu-item {
                list-style-type: none;
                position: relative;
                float: left;
                margin: 0 0 0 40px;

                a {
                    text-decoration: none;
                }

                .menu-item-link:first-child {
                    color: #fff;

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -3px;
                        left: 0;
                        width: 100%;
                        height: 0.15em;
                        background-color: $secondary;
                        opacity: 0;
                        transition: opacity 300ms, transform 300ms;
                    }

                    &:hover::after,
                    &:focus::after {
                        opacity: 1;
                        transform: translate3d(0, 0.2em, 0);
                    }

                    &::after {
                        opacity: 1;
                        transform: scale(0);
                        transform-origin: center;
                    }

                    &:hover::after,
                    &:focus::after{
                        transform: scale(1);
                    }
                }

                &.current {
                    .menu-item-link {
                        color: $secondary;
                        font-weight: 700;
                    }

                    .menu-item-link:first-child::after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                &:hover {
                    & .menu-sub {
                        display: flex;
                    }
                }
            }

            .menu-sub-wrapper {
                padding: 40px 0 0;
            }

            .menu-sub {
                display: none;
                position: absolute;
                top: 16px;
                left: -60px;
                padding: 30px 0 0;
                z-index: 99;

                .menu-sub-wrapper {
                    background: $primary-light;
                    padding: 30px 60px;
                    display: flex;
                    flex-direction: row;
                }

                a {
                    color: $primary-lightplus;
                }

                :first-child {
                    margin: 0 40px 0 0;
                }

                .menu-sub-cat {
                    .menu-sub-title {
                        margin: 0 0 20px;
                        padding: 0;
                        color: $primary-inverse;
                        font-size: 1em;
                        font-weight: 500;
                    }

                    .menu-sub-menu {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;

                        .menu-sub-item {
                            margin: 6px 0 0;
                            font-size: .9em;
                        }
                    }
                }
            }
        }
    }

    .content {
        position: relative;
        padding: 50px 0 0;
    }

    .foot {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        background: $primary-light;
        margin: 100px 0 0;
        padding: 40px 0;
        font-size: 0.9em;

        .foot-title {
            text-transform: uppercase;
            margin: 0 0 25px;
        }

        .foot-supplier {
            .foot-title {
                margin: 0 0 25px;
            }

            li {
                margin: 0 0 5px 0;
            }
        }

        .foot-partner {
            margin: 0 0 0 60px;

            .foot-title {
                margin: 0 0 10px;
            }

            menu {
                display: flex;
                flex-direction: row;
                align-items: center;
            }

            li {
                display: inline-block;
                margin: 0 20px 0 0;
            }

            img {
                opacity: .54;
                width: 80px;

                &.aplg {
                    width: 99px;
                }

                &.fgl {
                    width: 72px;
                }

                &.egb {
                    width: 85px;
                }
            }
        }

        .bot {
            margin: 120px 0 0;
        }

        .top, .bot {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: start;

            .side {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: start;

                menu {
                    font-size: 0.9em;
                }
            }

            menu {
                padding: 0;
                margin: 0;

                &.contact {
                    margin: 44px 80px 0;

                    li {
                        margin: 0 0 10px;

                        i {
                            margin: 0 5px 0 0;
                            color: $primary-lightplus;
                        }
                    }
                }

                &.social {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    li {
                        margin: 0 20px 0 0;

                        &:last-child {
                            margin: 0;
                        }

                        i {
                            font-size: 3em;

                            &.fa-youtube {
                                font-size: 3.6em;
                                margin: -3px 0 0;
                            }
                        }
                    }
                }

                &.legal {
                    display: flex;
                    flex-direction: row;

                    li {
                        margin: 0 20px 0 0;
                    }

                    a {
                        color: $primary-lightplus;
                    }
                }

                li {
                    list-style-type: none;

                    a {
                        text-decoration: none;
                        color: $primary-inverse;
                    }
                }
            }
        }
    }
}

.page-head {
    text-align: center;
    margin: 50px 0 70px;

    .page-title {
        font-size: 4em;
        text-transform: uppercase;
        margin: 0 0 15px;
        padding: 0;
        line-height: 0.9em;
        letter-spacing: 0.15em;
        font-weight: 700;
    }

    .page-subtitle {
        margin: 0;
        padding: 0;
        font-size: 1.5em;
        margin: 0 0 15px;
        color: $secondary;
        font-weight: 600;
    }

    .page-desc {
        font-size: 0.95em;
        line-height: 1.3em;
    }
}
