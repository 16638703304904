.map {
    width: 100%;
    height: 500px;
}

.office-content {
    text-align: center;

    .office-sep {
        display: inline-block;
        width: 1px;
        border: none;
        border-right: 1px solid $secondary;
        height: 60px;
        margin: 57px 0 70px;

        &:first-child {
            margin: 70px 0 43px;
        }
    }

    .office-txt {
        margin: 0 auto 20px;
        width: 64%;
        font-size: 0.85em;
        line-height: 1.5em;

        &.mea {
            font-size: 1.3em;
            line-height: 1.3em;
            font-weight: 700;
            width: 50%;
            margin-bottom: 0;
        }
    }
}

.office-foot {
    height: 415px;
    display: flex;
    flex-direction: row;

    .office-foot-left {
        background-position: center !important;
        background-size: cover !important;
        flex: 0 0 50%;
    }

    .office-foot-right {
        background: $secondary;
        color: $primary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 1 50%;
        padding: 0 110px;

        .office-foot-sep {
            display: inline-block;
            width: 60px;
            border: none;
            border-bottom: 2px solid $primary-inverse;
            height: 1px;
            margin: 30px 0;
        }

        .office-foot-title {
            color: $primary-inverse;
            text-align: center;
            font-size: 1.8em;
            text-transform: uppercase;
            margin: 0;
        }

        .office-foot-txt {
            text-align: center;
            font-size: 0.85em;
            line-height: 1.5em;
            font-weight: 500;
            margin: 0;
        }
    }
}
