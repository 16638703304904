.info-intro {
    position: relative;
    margin: 0 0 100px 0;

    &-img {
        top: 0;
        left: 360px;
        position: absolute;
        height: 670px;
        z-index: 1;
    }

    &-content {
        position: relative;
        z-index: 10;
        height: 670px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 0 100px;
    }

    &-title {
        font-size: 3em;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin: 30px 0 25px
    }

    &-txt {
        font-size: 0.8em;
        line-height: 1.5em;
        width: 20%;
    }
}

.info-qlt {
    text-align: center;

    &-title {
        font-size: 3em;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin: 30px 0 25px
    }

    &-txt {
        font-size: 0.8em;
        line-height: 1.5em;
        width: 40%;
        margin: 0 auto;
    }

    &-imgs {
        display: flex;
        flex-direction: row;
        margin: 50px 0 0;

        .info-qlt-img-sq {
            margin: 0 0 21px 25px;
        }
    }
}

.info-eng {
    height: 415px;
    width: 90%;
    margin: 125px auto 0;
    display: flex;
    flex-direction: row;

    &-left {
        background-position: center !important;
        background-size: cover !important;
        flex: 0 0 50%;

        .info-eng-copy {
            color: $primary-lightplus;
            font-size: 0.9em;
            margin: 5px 0 0;

            a {
                color: $primary-lightplus;
                text-decoration: none;
            }
        }
    }

    &-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 1 50%;

        .info-eng-title {
            font-size: 3em;
            font-weight: 700;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            margin: 30px 0 25px
        }

        .info-eng-txt {
            font-size: 0.8em;
            line-height: 1.5em;
            width: 63%;
            margin: 0 auto;
            text-align: center;
        }
    }
}

.info-pass {
    position: relative;
    width: 85%;
    margin: 180px auto 0;

    .info-pass-title {
        text-align: center;
        font-size: 3em;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin: 30px 0 25px
    }

    img {
        width: 750px;
    }

    .info-pass-txt {
        width: 360px;
        padding: 40px;
        background: $secondary;
        position: absolute;
        bottom: 50px;
        right: 15px;
        box-sizing: border-box;
        color: $primary;
        font-size: 0.9em;
        line-height: 1.5em;

        .sign {
            display: inline-block;
            margin: 10px 0 0;
            color: $primary-inverse;
        }
    }
}
