.home-wrapper {
    min-height: 2400px;
}

.home-title {
    position: absolute;
    left: 554px;
    color: $primary-inverse;
    font-size: 3.7em;
    line-height: 0.8em;
    width: 290px;
    font-weight: 700;
    letter-spacing: 0.07em;
    top: 36px;
}

.home-cat {
    position: relative;

    .home-cat-title {
        position: absolute;
        font-size: 9em;
        letter-spacing: 0.2em;
        color: $primary-inverse;
        text-transform: uppercase;
        z-index: 99;
        font-weight: 700;
    }

    &.cat1 {
        margin: -65px 0 0;

        .home-cat-title {
            bottom: 293px;
            left: 106px;
        }
    }

    &.cat2 {
        .home-cat-title {
            bottom: 90px;
            left: 169px;
        }
    }
}

.home-guitar {
    position: relative;
    color: $primary-inverse;
    font-size: 0.8em;
    display: none;

    .guitar-content {
        width: 125px;
        position: absolute;
    }

    .guitar-line {
        position: absolute;
        display: block;
        background-color: $secondary;
    }

    .guitar-title {
        position: absolute;
        font-size: 1.3em;
        text-transform: uppercase;
    }

    &.cat1-pos1 {
        margin-left: 230px;
        margin-top: -370px;

        img {
            height: 755px;
            width: 284px;
        }

        .guitar-line {
            bottom: -10px;
            left: 100px;
            height: 55px;
            width: 1px;
        }

        .guitar-title {
            bottom: -100px;
            left: 73px;
            writing-mode: vertical-lr;
            text-orientation: mixed;
        }

        .guitar-content {
            bottom: -100px;
            left: 125px;
        }
    }

    &.cat1-pos2 {
        margin-left: 577px;
        margin-top: -119px;
        z-index: 10;

        img {
            width: 755px;
            height: 284px;
        }

        .guitar-line {
            bottom: 65px;
            left: 276px;
            height: 1px;
            width: 55px;
        }

        .guitar-title {
            bottom: 40px;
            left: 342px;
        }

        .guitar-content {
            bottom: -30px;
            left: 344px;
        }
    }

    &.cat1-pos3 {
        margin-left: 384px;
        margin-top: -11px;

        img {
            height: 755px;
            width: 284px;
        }

        .guitar-line {
            top: 164px;
            left: -76px;
            width: 140px;
            height: 1px;
        }

        .guitar-title {
            top: 137px;
            left: -200px;
        }

        .guitar-content {
            top: 170px;
            left: -200px;
        }
    }

    &.cat1-pos4 {
        margin-left: 606px;
        margin-top: -614px;

        img {
            height: 755px;
            width: 284px;
        }

        .guitar-line {
            height: 57px;
            left: 200px;
            top: 442px;
            width: 1px;
        }

        .guitar-title {
            left: 173px;
            top: 347px;
            writing-mode: vertical-lr;
            text-orientation: mixed;
        }

        .guitar-content {
            left: 225px;
            top: 334px;
        }
    }

    &.cat2-pos1 {
        margin-top: -36px;
        margin-left: 0px;

        img {
            width: 755px;
            height: 284px;
        }

        .guitar-line {
            top: -24px;
            left: 118px;
            width: 1px;
            height: 74px;
        }

        .guitar-title {
            top: -166px;
            left: 61px;
        }

        .guitar-content {
            top: -135px;
            left: 62px;
        }
    }

    &.cat2-pos2 {
        margin-top: -30px;
        margin-left: 155px;

        img {
            width: 755px;
            height: 284px;
        }

        .guitar-line {
            top: 245px;
            left: 302px;
            width: 74px;
            height: 1px;
        }

        .guitar-title {
            top: 218px;
            left: 387px;
        }

        .guitar-content {
            top: 250px;
            left: 387px;
        }
    }
}
